import React from 'react';
import Helmet from "react-helmet";
import Contact from 'components/pages/Contact';
import Layout from 'components/Layout';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const ContactPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <title>Contact Us | Shipkoo</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Contact us at info@shipkoo.com. Be Cool, Ship Globally!" />
        <meta property="og:title" content="Contact Us | Shipkoo" />
        <meta property="og:description" content="Contact us at info@shipkoo.com. Be Cool, Ship Globally!" />
        <meta name="twitter:title" content="Contact Us | Shipkoo" />
        <meta name="twitter:description" content="Contact us at info@shipkoo.com. Be Cool, Ship Globally!" />
      </Helmet>
      <Contact />
    </Layout>
  );
};

export default ContactPage;

