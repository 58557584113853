import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import styled from 'styled-components';
import { scrollToRef } from 'common/scrollToRef';
import { BaseP, HeaderMedium, BaseTransparentP } from 'components/typography';
import { Phone, TextSection, PhoneNumbers } from './styled';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import ArrowButton from 'components/Button/ArrowButton';
import { WidthWrapper, RelativeWrapper } from 'components/wrappers';
import { MobileFilter } from 'components/BackgroundFilter';
import ContactForm from './ContactForm';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const CompanyInfo = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgContactQuery {
      file(relativePath: { eq: "contact-woman_.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const BasePTransparent = styled.p`
    opacity: 1;
    line-height: 35px;
    color: rgb(164, 176, 173);
    margin-bottom: 46px;
    font-size:14px;
    max-width: 375px;

    @media ${mediaMin(breakpoint.desktop)} {
        font-size: 16px;
    }

    @media only screen and (max-width: 1023px) {
        color:#fff;
    }
  `;
  const MainTitle = styled.h1`
    max-width: 325px;
    font-size: 28px;
    font-weight: 500;
    margin: 42px 0 auto;

  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
      margin: 45px 0;
  }

  @media only screen and (max-width: 1023px) {
     color:#fff;

  }
`;

  const ref = useRef(null);

  const handleClick = () => {
    scrollToRef(ref, 200);
    if (ref && ref.current) {
      ref.current.children[0].children[1].focus();
    }
  };
  return (
    <>
      <RelativeWrapper breakLine>
        <WidthWrapper size={1440} whiteMobile>
          <TextSection>
            <BaseP>{intl.formatMessage({ id: "c-contact-header" })}</BaseP>
            <MainTitle>{intl.formatMessage({ id: "c-contact-subheader" })}</MainTitle>
            <BasePTransparent>
              {intl.formatMessage({ id: "c-contact-description" })}
            </BasePTransparent>
            <PhoneNumbers>
              <BaseP>{intl.formatMessage({ id: "c-contact-email-title" })}</BaseP>
              <Phone href="mailto:info@shipkoo.com">{intl.formatMessage({ id: "c-contact-email" })}</Phone>
            </PhoneNumbers>
            <ArrowButton onClick={handleClick} >
              {intl.formatMessage({ id: "c-contact-button" })}
            </ArrowButton>
          </TextSection>
        </WidthWrapper>
        <ImageBox>
          <GatsbyImage fluid={data.file.childImageSharp.fluid} />
          <MobileFilter />
        </ImageBox>
      </RelativeWrapper>
      <ContactForm innerRef={ref} />
    </>
  );
};

export default CompanyInfo;
