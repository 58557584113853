import React, { useState, useCallback, RefObject } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Input from 'components/Input';
import ArrowButton from 'components/Button/ArrowButton';
import { Wrapper, HalfBox, Box, ErrorMessage } from './styled';

const ERROR_MESSAGE = 'Server Error, message cannot be send. Please contact us by phone.';

const ButtonContainer = styled.div`

 .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }
`;

interface FormData {
  company_name: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface OwnProps {
  innerRef: RefObject<any>;
}

const CompanyInfo = ({ innerRef }: OwnProps) => {
  const intl = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            functionEndpoint
          }
        }
      }
    `,
  );
  const { register, handleSubmit, errors } = useForm<FormData>();
  const getErrors = (name: keyof FormData) => errors[name] && errors[name]?.message;
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = useCallback((data: FormData) => {
    setLoading(true);
    setError('');
    fetch(site.siteMetadata.functionEndpoint, {
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(response => {
        if (response.status === 500) {
          setError(ERROR_MESSAGE);
          setSubmitted(true);
        } else if (response.status === 400) {
          setError('Form Error, please check if provided data is correct');
        } else {
          setSubmitted(true);
        }
      })
      .catch(() => setError(ERROR_MESSAGE))
      .finally(() => {
        setLoading(false);
      });
  }, [site.siteMetadata.functionEndpoint]);

  return (
    /*<p>onSubmit={handleSubmit(onSubmit)}</p>*/
    <Wrapper action="https://www.elformo.com/forms/ff790466-bed7-485b-bcb0-ca4148e7b7c1" method="post" accept-charset="UTF-8">
      <HalfBox ref={innerRef}>
        <Input
          label={intl.formatMessage({ id: "c-contact-form-input1-label" })}
          placeholder={intl.formatMessage({ id: "c-contact-form-input1-placeholder" })}
          ref={register({ required: 'Company name is required', maxLength: 30 })}
          name="company_name"
          error={getErrors('company_name')}
        />
      </HalfBox>
      <HalfBox>
        <Input
          label={intl.formatMessage({ id: "c-contact-form-input2-label" })}
          placeholder={intl.formatMessage({ id: "c-contact-form-input2-placeholder" })}
          name="name"
          ref={register({ required: 'Name is required', maxLength: 80 })}
          error={getErrors('name')}
        />
      </HalfBox>
      <HalfBox>
        <Input
          label={intl.formatMessage({ id: "c-contact-form-input3-label" })}
          placeholder={intl.formatMessage({ id: "c-contact-form-input3-placeholder" })}
          type="tel"
          name="phone"
          ref={register({ required: 'Phone number is required', maxLength: 18 })}
          error={getErrors('phone')}
        />
      </HalfBox>
      <HalfBox>
        <Input
          label={intl.formatMessage({ id: "c-contact-form-input4-label" })}
          placeholder={intl.formatMessage({ id: "c-contact-form-input4-placeholder" })}
          type="email"
          name="email"
          ref={register({ required: 'E-mail is required', pattern: /^\S+@\S+$/i })}
          error={getErrors('email')}
        />
      </HalfBox>
      <Box>
        <Input
          label={intl.formatMessage({ id: "c-contact-form-input5-label" })}
          placeholder={intl.formatMessage({ id: "c-contact-form-input5-placeholder" })}
          type="textarea"
          name="message"
          ref={register({ required: 'Please fill the message', maxLength: 500 })}
          error={getErrors('message')}
        />
        <input style={{display: "none"}} type="text" value="" name="_empty_field"/>
      </Box>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ButtonContainer>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <ArrowButton type="submit" disabled={submitted} className="send-form">
              {submitted ? 'Message Sent' : loading ? 'Loading...' : 'Send Message'}
            </ArrowButton>
          </div>
          <div className="zh">
            <ArrowButton type="submit" disabled={submitted} className="send-form">
              {submitted ? '消息发送' : loading ? 'Loading...' : '发送消息'}
            </ArrowButton>
          </div>
          <div className="kr">
            <ArrowButton type="submit" disabled={submitted} className="send-form">
              {submitted ? '보낸 메시지' : loading ? 'Loading...' : '메시지 보내기'}
            </ArrowButton>
          </div>
          <div className="ja">
            <ArrowButton type="submit" disabled={submitted} className="send-form">
              {submitted ? 'メッセージを送信しました' : loading ? '読み込んでいます...' : 'メッセージを送る'}
            </ArrowButton>
          </div>
        </div>
      </ButtonContainer>
    </Wrapper>
  );
};

export default CompanyInfo;
