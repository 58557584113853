import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { baseFontStyle } from 'components/typography';
import ArrowButton from 'components/Button/ArrowButton';

export const Wrapper = styled.form`
  max-width: ${780 + 30}px;
  margin: 60px 0 60px -45px;
  padding: 0 15px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-left: 46px;
  }

  ${ArrowButton} {
    margin: 30px auto 0;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    margin: 120px 0 120px -45px;
  }
`;

export const HalfBox = styled.div`
  flex: 1 35%;
  max-width: 100%;
  margin-bottom: 32px;

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 43%;
    margin-bottom: 45px;
  }
`;

export const Box = styled.div`
  flex: 1 100%;
  margin-bottom: 30px;
  max-width: 100% !important;
  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 60px;
  }
`;

export const ErrorMessage = styled.span`
  margin: 0 auto 30px;
  min-width: 100%;
  text-align: center;
  ${baseFontStyle};
  color: #ec5990;
`;
